import React, { useContext, useEffect } from "react";
import SEO from "../../components/seo";

import { StateContext } from "../../state/StateProvider";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

// Sections
import Layout from "../../components/layout";

// DATA
import { useStaticQuery, graphql } from "gatsby";

export default function CampaignsPage({ data }) {
  const { campaignImages, allMarkdownRemark } = data;
  const campaigns = allMarkdownRemark.edges;

  const { isDark, setTheme } = useContext(StateContext);
  useEffect(() => {
    setTheme(true);
  });

  return (
    <Layout>
      <SEO
        keywords={[`sparkrise`, `campaigns`]}
        title="SparkRise® | Campaigns"
      />
      {campaigns.map((camp, i) => {
        const campaignData = camp.node.frontmatter;
        const image = campaignImages.nodes.find(
          (imageObj) => imageObj.name === campaignData.hero.image
        );
        return (
        <div className="bg-dark-purple">
          <section
            id=""
            className="relative flex flex-col justify-end w-full mx-auto max-h-screen bg-black aspect-3/4 sm:aspect-4/3 lg:aspect-19/10"
            key={`campaign-${i}`}
          >
            <GatsbyImage
              image={image.childImageSharp.gatsbyImageData}
              objectPosition={campaignData.hero.objectPosition}
              alt={image.name}
              className="!absolute inset-0 gradient-overlay"
              imgClassName="absolute inset-0"
            />
            <div className="relative z-10 flex flex-col justify-end py-8 container-wide text-shadow">
              <div className="w-full text-white md:w-2/3 2xl:w-1/2">
                <p
                  className="uppercase c-h6 text-orange"
                  data-sal="fade"
                  data-sal-duration="600"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                >
                  Support {campaignData.title}
                </p>
                <h2
                  className="c-h2"
                  data-sal="fade"
                  data-sal-duration="600"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                >
                  {campaignData.thumbnail.heading}
                </h2>
                <p
                  data-sal="fade"
                  data-sal-duration="600"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                >
                  {campaignData.thumbnail.subheading}
                </p>
                {campaignData.thumbnail.alertMessage && (
                  <p className="italic bg-opacity-50 bg-orange">
                    {campaignData.thumbnail.alertMessage}
                  </p>
                )}
                <Link
                  to={campaignData.thumbnail.button.url}
                  className="mt-6 c-button text-shadow-none"
                  data-sal="slide-up"
                  data-sal-duration="600"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                >
                  {campaignData.thumbnail.button.text}
                </Link>
              </div>
            </div>
          </section>
          </div>
        );
      })}
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "campaign-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            hero {
              image
              objectPosition
            }
            thumbnail {
              alertMessage
              heading
              subheading
              button {
                text
                url
              }
            }
          }
        }
      }
    }
    campaignImages: allFile(
      filter: { relativeDirectory: { eq: "campaign-images" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
        }
      }
    }
  }
`;
